import React from 'react';
import FlowChart from './components/FlowChart';
import './App.css';

const App = () => {
  return (
    <div className="app-container">
      <h4>RYR CSFF Dispatch</h4>
      <FlowChart />
    </div>
  );
};

export default App;
