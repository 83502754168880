const flowChartData = {
  alertMessage: "Please use this app responsibly.",
  startNode: "start",
  nodes: {
    start: {
      question: "FCOM Vol. 1 SP 3.1",
      subtitle: "Frost may form due to cold-soaked fuel touching the wing after long flights with large fuel loads. <br><br> <ul><li>Tankering on previus sector ?</li><li>Arrival fuel > 4 tons ?</li><li>Frost on lower wing beyond stick 4 ?</li></ul><h3>If in doubt. De-Ice !</h3>",
      options: [
        { answer: "OK", nextNode: "node2" }
      ],
      progress: 0
    },
    node2: {
      question: "CSFF on <u>lower</u> wing ?",
      subtitle: "",
      options: [
        { answer: "YES", nextNode: "node3" },
      ],
      progress: 10
    },
    node3: {
      question: "CSFF on <u>upper</u> wing ?",
      subtitle: "",
      options: [
        { answer: "YES", nextNode: "nodeB1" },
        { answer: "NO", nextNode: "nodeA0" },
      ],
      progress: 20
    },
    nodeA0: {
      question: "Leading edge devices, control surf., tabs, winglets... Free of snow, ice and frost ?",
      subtitle: "",
      options: [
        { answer: "YES", nextNode: "nodeA1" },
        { answer: "NO", nextNode: "dispatchNotAllow" },
      ],
      progress: 40
    },
    nodeA1: {
      question: "OAT at or above +4ºC ?",
      subtitle: "",
      options: [
        { answer: "YES", nextNode: "nodeA2" },
        { answer: "NO", nextNode: "nodeAA1" },
      ],
      progress: 50
    },
    nodeA2: {
      question: "Fuel Tank Temp at or above -16ºC ?",
      subtitle: "",
      options: [
        { answer: "YES", nextNode: "nodeA3" },
        { answer: "NO", nextNode: "nodeAA1" },
      ],
      progress: 60
    },
    nodeA3: {
      question: "Precipitation or visible moisture ?",
      subtitle: "(rain, snow, drizzle or fog with < 1600m vis.)",
      options: [
        { answer: "YES", nextNode: "nodeAA1" },
        { answer: "NO", nextNode: "dispatchAllow" },
      ],
      progress: 70
    },
    nodeAA1: {
      question: "Is CSFF on lower wing less than 3 mm ?",
      subtitle: "The Frost Measuring Tool in the Techlog should be used. The pilot will take a measurement of the thickness of the frost on the lower wing tank surfaces, inboard of the Eng. Pylons aprox. 10cm, assuring that the edge of the tool is actually in contact with the wing skin and moving in line with the wing cord.",
      options: [
        { answer: "YES", nextNode: "dispatchAllow" },
        { answer: "NO", nextNode: "dispatchNotAllow" },
      ],
      progress: 80
    },
/*     nodeA: {
      question: 'Which programming language do you prefer?',
      options: [
        { answer: 'JavaScript', nextNode: 'node4', image: 'path/to/javascript.png' },
        { answer: 'Python', nextNode: 'node4', image: 'path/to/python.png' }
      ]
    }, */
    nodeB1: {
      question: "OAT at or above +4ºC ?",
      subtitle: "",
      options: [
        { answer: "YES", nextNode: "nodeB2" },
        { answer: "NO", nextNode: "dispatchNotAllow" },
      ],
      progress: 30
    },
    nodeB2: {
      question: "Fuel Tank Temp at or above -16ºC ?",
      subtitle: "",
      options: [
        { answer: "YES", nextNode: "nodeB3" },
        { answer: "NO", nextNode: "dispatchNotAllow" },
      ],
      progress: 45
    },
    nodeB3: {
      question: "Precipitation or visible moisture ?",
      subtitle: "(rain, snow, drizzle or fog with < 1600m vis.)",
      options: [
        { answer: "YES", nextNode: "dispatchNotAllow" },
        { answer: "NO", nextNode: "nodeB4" },
      ],
      progress: 60
    },
    nodeB4: {
      question: "CSFF only within the CSFF area ?",
      subtitle: "",
      options: [
        { answer: "YES", nextNode: "nodeB5" },
        { answer: "NO", nextNode: "dispatchNotAllow" },
      ],
      progress: 75
    },
    nodeB5: {
      question: "Leading edge devices and control surfaces <b>free</b> of snow, ice and frost ?",
      subtitle: "",
      options: [
        { answer: "YES", nextNode: "dispatchAllow" },
        { answer: "NO", nextNode: "dispatchNotAllow" },
      ],
      progress: 90
    },
    dispatchAllow: {
      message: "Dispatch allowed",
      options: [
        { answer: "Restart", nextNode: "start" }
      ],
      progress: 100
    },
    dispatchNotAllow: {
      message: "Dispatch <b><u>NOT</u></b> allowed. De-Ice!",
      options: [
        { answer: "Restart", nextNode: "start" }
      ],
      progress: 100
    }
  }
};

export default flowChartData;
