import React, { useState, useEffect } from 'react';
import flowChartData from '../flowChartData';
import ProgressComponent from './ProgressComponent';
import './FlowChart.css';

const FlowChart = () => {
    const [currentNodeId, setCurrentNodeId] = useState(flowChartData.startNode);
    const [progress, setProgress] = useState(flowChartData.nodes[flowChartData.startNode].progress || 0);
    const [showWarning, setShowWarning] = useState(true);

    const currentNode = flowChartData.nodes[currentNodeId];

    useEffect(() => {
        setProgress(currentNode.progress || 0);
    }, [currentNodeId, currentNode]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowWarning(false);
        }, 5000);

        return () => clearTimeout(timer);
    }, []);

    const handleOptionClick = (nextNodeId) => {
        setCurrentNodeId(nextNodeId);
        setShowWarning(false);
    };

    const handleCloseClick = () => {
        setShowWarning(false);
    };

    return (
        <div className="flow-chart-container">
            <ProgressComponent progress={progress} />
            {currentNode.question ? (
                <div className="question-container">
                    <h5
                        className="minimalist-title"
                        dangerouslySetInnerHTML={{ __html: currentNode.question }}
                    />
                    {currentNode.subtitle && (
                        <p className="subtitle" dangerouslySetInnerHTML={{ __html: currentNode.subtitle }} />
                    )}
                    <div className="options-container">
                        {currentNode.options.map((option, index) => (
                            <div key={index} className="option" onClick={() => handleOptionClick(option.nextNode)}>
                                {option.image ? (
                                    <div className="option-image-container">
                                        <img src={option.image} alt={option.answer} className="option-image" />
                                        <p className="option-text">{option.answer}</p>
                                    </div>
                                ) : (
                                    <button className="option-button">{option.answer}</button>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div className="message-container">
                    <h3
                        className="minimalist-title"
                        dangerouslySetInnerHTML={{ __html: currentNode.message }}
                    />
                    <button className="restart-button" onClick={() => setCurrentNodeId(flowChartData.startNode)}>
                        Restart
                    </button>
                </div>
            )}
            {showWarning && (
                <div className="warning-banner">
                    <p className="warning-text">For reference only. Use under your own responsibility.</p>
                    <button className="close-button" onClick={handleCloseClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                            <path fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"/>
                        </svg>
                    </button>
                </div>
            )}
        </div>
    );
};

export default FlowChart;
