import React from 'react';
import { ProgressBar } from 'react-bootstrap';

const ProgressComponent = ({ progress }) => {
  return (
    <div className="progress-bar-container">
      <ProgressBar now={progress} />
    </div>
  );
};

export default ProgressComponent;
